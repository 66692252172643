// import { initializeApp } from 'firebase/app';
// import { getDatabase } from 'firebase/database';

// import { initializeApp } from 'firebase/app'
// import 'firebase/firestore'
// import 'firebase/auth'
// import 'firebase/storage'
// import 'firebase/database'

const firebaseConfig = {
    apiKey: "AIzaSyBKQFRA14ZAynjYwOt1xasSJJHv2Pu_p9w",
    authDomain: "test-demo-auth.firebaseapp.com",
    databaseURL: "https://test-demo-auth.firebaseio.com",
    projectId: "test-demo-auth",
    storageBucket: "test-demo-auth.appspot.com",
    messagingSenderId: "753191615866",
    appId: "1:753191615866:web:15e0ea5a92cd72f2bdaac3",
    measurementId: "G-3YXW28C49S"
};
// const firebaseApp = initializeApp(config);

// export default firebaseApp;

// const firebaseApp = initializeApp(firebaseConfig);

// // Get a reference to the Firebase database service
// const firebaseDB = getDatabase(firebaseApp);

export {
    firebaseConfig,
    // firebaseApp,
    // firebaseDB
}