import React, { useEffect, useState } from "react";
import { firebaseConfig } from '../../config/index';
import firebase from "firebase/app";
import "firebase/database";
import './home.css'

firebase.initializeApp(firebaseConfig)
var database = firebase.database();

export const Home = () => {

  const [url, setUrl] = useState('https://www.youtube.com/embed/MVA4GtmFWLo_?version=3&enablejsapi=1&showinfo=0&controls=0&rel=0&showinfo=0&disablekb=1&iv_load_policy=3&modestbranding=0')
  const [id, setId] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      getPublicUrl()
      // setTimeout(() => {
      //   // document.getElementsByClassName('ytp-watch-later-button ytp-button ytp-show-watch-later-title')[0].style.display = 'none'
      //   // document.getElementsByClassName('ytp-button ytp-share-button ytp-share-button-visible ytp-show-share-title')[0].style.display = 'none'
      //   // document.getElementsByTagName('iframe')[0].contentWindow.getElementsByClassName('ytp-watch-later-button')[0].style.display = 'none';
      // }, 1000)
    }, 1800)
    setTimeout(() => {
      // doMaximizeIframe()
    }, 800)
    setTimeout(()=> {
      // console.log('hideeeeeeeee', document.querySelector('.ytp-chrome-top-buttons'))
      document.querySelector('.ytp-chrome-top-buttons')
    }, 3000)
  }, [])

  const doMaximizeIframe = (e) => {
    e && e.preventDefault();
    var element = document.getElementById(`play_video-${id}`);
    if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    }
  }

  const username = localStorage.getItem('user');

  const _logout = (e) => {
    e.preventDefault();
    clearStatus(username, e);
  }

  const clearStatus = (u, e) => {
    e.preventDefault();
    fetch("https://us-central1-test-demo-auth.cloudfunctions.net/logout?username=" + u, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({ username: u })
    }).then(response => response.json())
      .then(data => {
        console.log('Logout...!', data);
        localStorage.removeItem('user');
        localStorage.clear();
        window.location.reload(true);
      })
      .catch(err => {
        console.log("Error", err)
      });
  };

  const getPublicUrl = () => {
    database.ref('/publicUrl').on('value', (snapshot) => {
      const node = snapshot.val();
      const url = `https://www.youtube.com/embed/${node.videoId}?version=3&enablejsapi=1&showinfo=0&controls=0&rel=0&showinfo=0&disablekb=1&iv_load_policy=3&modestbranding=0`
      setUrl(url);
    });
  }

  const playVideo = () => {
    // let u = url;
    // u += '&autoPlay=1'
    // setUrl(u)
    document.getElementById(`play_video-${id}`).contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
  }

  // $('a.play-video').click(function () {
  //   $('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
  // });

  // $('a.stop-video').click(function () {
  //   $('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
  // });

  // $('a.pause-video').click(function () {
  //   $('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
  // });

  return (
    <div style={{
      width: "100%", height: "100vh",
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <div>
        <button style={{ height: 40, width: 100, borderRadius: 6, background: 'rgba(236, 215, 168, 0.60)', color: 'black', fontWeight: 700, margin: '10px' }} onClick={playVideo}>Play Video</button>
        <button style={{ height: 40, width: 100, borderRadius: 6, background: 'green', color: 'white', fontWeight: 700, margin: '10px' }} onClick={doMaximizeIframe}>Full Screen</button>
        <button style={{ height: 40, width: 100, borderRadius: 6, background: 'red', color: 'white', fontWeight: 700, margin: '10px' }} onClick={_logout}>Logout</button>
      </div>
      <hr />

      {/* <iframe
        width="560"
        height="315"
        src={"https://www.youtube.com/embed/VywxIQ2ZXw4"} https://www.youtube.com/embed/fcZwUxZNxDw
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
      </iframe> */}
      {/* <iframe width="560" height="315" src="" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
      <div className="videoWrapper" style={{
        pointerEvents: 'none',
        width: "100%",
        height: "100%"
      }}>
        <iframe
          id={`play_video-${id}`}
          width="100%"
          height="90%"
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          webkitallowfullscreen={"true"}
          mozallowfullscreen={"true"}
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};
