import React, {useState} from 'react';
import { Login} from './Components';
import './App.css';

const user = localStorage.getItem('user');

function App() {
  return (
    <div className="App">
      {/* <h1>Thank You !!!</h1> */}
      <Login user={user}/>
    </div>
  );
}

export default App;
