import React, { useState } from "react";
import { Home } from "./../";
import { users } from "../../const/us3r";
export const Login = ({ user }) => {

  const [credentials, setCredentials] = useState({
    username: "",
    password: ""
  });
  const [isUsrLogin, setUserState] = useState(false);
  const [isErr, setErr] = useState(null);

  const setCredentialsState = (key, value) => {
    setCredentials({ ...credentials, [key]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    // if (users.toString().indexOf(`${credentials.username},`) === -1) {
      fetch("https://us-central1-test-demo-auth.cloudfunctions.net/login", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify(credentials)
      })
        .then(response => response.json())
        .then(data => {
          // console.log('dataaa ', data);
          if (data.sucess) {
            localStorage.setItem('user', credentials.username);
            setUserState(data.sucess);
          } else {
            setErr(data.err);
          }

        })
        .catch(err => {
          console.log("Error", err)
          setErr(err)
        });
    // } else {
    //   if (credentials.password == credentials.username.substr(credentials.username.length - 5)) {
    //     localStorage.setItem('user', credentials.username);
    //     setUserState(true);
    //   } else {
    //     setErr('Please check your HOF and password');
    //   }
    // }
  };

  const showRespectiveOption = () => {
    if (isUsrLogin || user) {
      return (<Home />)
    } else {
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
          <form
            style={{
              borderRadius: '10px !important',
              backgroundColor: 'rgba(236, 215, 168, 0.60)',
              padding: 2,
              border: 'solid 1px #d5b789',
              boxShadow: '0 15px 30px rgb(0 0 0 / 10%)',
              transition: 'all .2s linear',
              display: 'flex',
              justifyContent: 'center'
            }}
            onSubmit={handleSubmit}>
            <table>
              <tbody>
                <tr>
                  <th colSpan="2">
                    <h3 style={{
                      color: 'rgb(16 93 21)',
                      textAlign: 'center',
                      textTransform: 'uppercase',
                      margin: 10
                    }}>
                      AMM LIVE
                    </h3>
                  </th>
                </tr>
                <tr>
                  <th colSpan="2">
                    <h3 style={{
                      color: '#c50409',
                      borderBottom: 'solid 1px #c50409',
                      textAlign: 'center',
                      textTransform: 'uppercase',
                      margin: 10
                    }}>
                      {"Al Mohallat ul Muhammediyah"}
                    </h3>
                  </th>
                </tr>
                <tr style={{ textAlign: "left" }}>
                  <th style={{ paddingLeft: 10 }}><label>ITS ID:</label></th>
                  <td><input
                    type="text"
                    autoComplete="off"
                    placeholder="404*****"
                    style={{
                      padding: '12px 20px',
                      margin: '8px 0',
                      boxSizing: 'border-box',
                      borderRadius: '5px',
                      border: 'solid 1px #d5b789'
                    }}
                    onChange={e => setCredentialsState("username", e.target.value)}
                  /></td>
                </tr>
                <tr style={{ textAlign: "left" }}>
                  <th style={{ paddingLeft: 10 }}><label>Password:</label></th>
                  <td> <input
                    type="password"
                    autoComplete="off"
                    placeholder="****"
                    style={{
                      padding: '12px 20px',
                      margin: '8px 0',
                      boxSizing: 'border-box',
                      borderRadius: '5px',
                      border: 'solid 1px #d5b789'
                    }}
                    onChange={e => setCredentialsState("password", e.target.value)}
                  /></td>
                </tr>
                <tr>
                  <th>
                    {" "}
                  </th>
                  <td>
                    <input type="submit" style={{
                      padding: '12px 20px',
                      margin: '8px 0',
                      boxSizing: 'border-box',
                      borderRadius: '5px',
                      background: '#8a2900',
                      color: '#fffaf0',
                      borderColor: "rgba(236, 215, 168, 0.60)",
                      width: "60%"
                    }} />
                  </td>
                </tr>
                <tr>
                  <th colSpan="2">
                    {isErr && <p style={{ color: 'red' }}>
                      {isErr}
                    </p>}
                  </th>
                </tr>
              </tbody>
            </table>
            {/* <label>ITS</label>
            <input
              type="text"
              autoComplete="off"
              placeholder="404*****"
              onChange={e => setCredentialsState("username", e.target.value)}
            />
            <br />
            <label>Password</label>
            <input
              type="password"
              autoComplete="off"
              placeholder="****"
              onChange={e => setCredentialsState("password", e.target.value)}
            />
            <br /> */}
            {/* <input type="submit" style={{
              padding: '12px 20px',
              margin: '8px 0',
              boxSizing: 'border-box',
              borderRadius: '5px'
            }} />
            {isErr && <p style={{ color: 'red' }}>
              {isErr}
            </p>} */}
          </form>
        </div >
      )
    }
  }

  return (
    <div style={{ width: "100%" }}>
      {showRespectiveOption()}
    </div>
  );
};
